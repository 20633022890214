/**
 * Created by daniel on 21/9/24.
 */
import React from 'react';
import { connect } from 'react-redux'
import PublishLibraryView from "../../views/PublishLibraryView";
import SessionManager from "../../utils/SessionManager";
import {
    DatasetPublishingInfoResult, DatasetResult, type EbaseResultMapStringNumber,
    EnvidObjectSmallResult,
    SiteDataResult, UserResult,
    VersionDetailsSmallResult
} from "../../fetchapi";
import { Dimensions, DispatchAction, EbaseAppState} from "../../redux/reducers";
import {PropsWithLocation} from "../../index";
import {Dispatch} from "redux";
import Spinner from "../common/Spinner";
import EbaseDS from "../../utils/EbaseDS";
import sitedata from "../../utils/sitedata";

interface PublishingStateProps {
    isLoading: boolean;
    dimensions: Dimensions | null;
    siteData: SiteDataResult | null;
    dataset: DatasetResult | null | undefined;
    changedObjs: EnvidObjectSmallResult[];
    historyRecords: VersionDetailsSmallResult[];
    historyVersionObjs: EnvidObjectSmallResult[];
    publishedVersion: VersionDetailsSmallResult | null | undefined;
    userList: UserResult[];
    currLibraryOwner: {value: string, label: string};
    publishSummary: any;
    isPublishSummaryLoading: boolean;
    canPublish: boolean
}

interface PublishingDispatchProps {
    loadAll: () => void;
    loadPublishInfo: () => void;
    loadOwnerList: () => void;
    changeOwner: (userId: string) => void;
    unmountData: () => void;
    loadHistoryVersion: (versionId: string | null) => void;
    loadLibrarySummary: () => void;
    viewObjectsWithState: (publishingState: any) => void;
}

export type PublishingHomeProps = PublishingStateProps & PublishingDispatchProps;

const mapStateToProps = (state: EbaseAppState, ownProps: PropsWithLocation) : PublishingStateProps =>
{
    let currOwner = state.manage.ownerOptions.find((u) => (u.userId === state.session.siteData?.dataset?.ownerId))
    let currLabel = currOwner ? (currOwner.firstname + " " + currOwner?.lastname + (currOwner.email ? (" - " + currOwner.email) : "")) : ""
    // console.log(' all state', state);
    return {
        dimensions:state.session.dimensions,
        siteData: state.session.siteData,
        dataset: state.session.siteData?.dataset,
        changedObjs: state.manage.changedObjects,
        historyRecords: state.manage.historyRecords,
        historyVersionObjs: state.manage.historyVersionObjects,
        isLoading: state.manage.isLoading,
        publishedVersion: state.manage.publishedVersion,
        userList: state.manage.ownerOptions,
        currLibraryOwner: {value: state.session.siteData?.dataset?.ownerId ?? "", label: currLabel},
        publishSummary: state.summary.publishSummary,
        isPublishSummaryLoading: state.summary.isLoading,
        canPublish: sitedata.checkWriteAccess({siteData: state.session.siteData!}, 'user')
    };
};

const mapDispatchToProps = (dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation):PublishingDispatchProps =>
{
    return {
        loadAll: () => loadAll(dispatch, ownProps),
        loadPublishInfo: () => loadPublishingInfo(dispatch, ownProps),
        unmountData: () => unmountPublishData(dispatch, ownProps),
        loadOwnerList: () => loadUserList(dispatch, ownProps),
        changeOwner: (userId: string) => changeLibOwner(userId, dispatch, ownProps),
        // loadChangedObjects: (versionId: string | null) => loadLibraryChanges(versionId, dispatch, ownProps),
        // loadHistory: () => loadHistoryRecords(dispatch, ownProps),
        loadHistoryVersion: (versionId: string | null) => loadLibraryVersionObjects(versionId, dispatch, ownProps),
        loadLibrarySummary: () => loadLibraryPublishSummary(dispatch, ownProps),
        viewObjectsWithState: (publishState) => viewObjectsWithPublishState(dispatch,publishState,ownProps),
    };
};


const loadLibraryVersionObjects = (versionId: string | null , dispatch: any, ownProps: PropsWithLocation) =>{
    let ebaseDs = SessionManager.shared().getEbaseDS();
    dispatch({type: 'LOAD_HISTORY_VERSION_START'});

    if (!!versionId) {
        //Promise<EnvidObjectSmallResult[]>
        ebaseDs.loadDatasetChangedObjects(versionId).then((result: EnvidObjectSmallResult[]) => {
            dispatch({type: 'LOAD_HISTORY_VERSION_DONE', data: result});
            // console.log(' LOAD_HISTORY_VERSION_DONE result', result);

        }).catch((err: any) => {
            console.log('error loadLibraryChanges', err);
            dispatch({type: 'LOAD_HISTORY_VERSION_DONE', data: []});
        });
    }
}

const loadPublishingInfo = (dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation) => {
    let ebaseDs = SessionManager.shared().getEbaseDS();
    dispatch({type: 'LOAD_PUBLISH_INFO_START'});

    ebaseDs.loadDatasetPublishingInfo().then((result: DatasetPublishingInfoResult) => {
        dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: result});
    }).catch( (err : any) => {
        console.log('error loadLibraryChanges', err);
        dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: {}});
    });
}

const loadUserList = (dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation) => {
    const ebaseDs = SessionManager.shared().getEbaseDS();
    dispatch({type: 'LOAD_OWNER_INFO_START'});

    ebaseDs.getUsersWithAccessToCurrentDataset().then(res => {
        dispatch({type: 'LOAD_OWNER_INFO_DONE', data: res});
    }).catch( err => {
        dispatch({type: 'LOAD_OWNER_INFO_DONE', data: []});
        SessionManager.shared().showToast("Loading Error",err, 'danger');
    })
}

const loadAll = (dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation) => {
    const ebaseDs = SessionManager.shared().getEbaseDS();
    dispatch({type: 'LOAD_OWNER_INFO_START'});
    dispatch({type: 'LOAD_PUBLISH_INFO_START'});

    Promise.all([
        ebaseDs.loadDatasetPublishingInfo(),
        ebaseDs.getUsersWithAccessToCurrentDataset()
    ])
        .then(([datasetInfoResult, usersAccessResult]) => {
            dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: datasetInfoResult});
            dispatch({type: 'LOAD_OWNER_INFO_DONE', data: usersAccessResult});
            // Add your logic here using both results
        })
        .catch(error => {
            dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: {}});
            dispatch({type: 'LOAD_OWNER_INFO_DONE', data: []});
            SessionManager.shared().showToast("Loading Error",error, 'danger');
        });
}

const changeLibOwner = (userId: string, dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation) => {
    let ebaseDs = SessionManager.shared().getEbaseDS();
    dispatch({type: 'UPDATE_LIBRARY_OWNER_START'});

    ebaseDs.changeLibraryOwner(userId).then(res => {
        dispatch({type: 'UPDATE_LIBRARY_OWNER_DONE', data: res});
    }).catch((err: any) => {
        dispatch({type: 'UPDATE_LIBRARY_OWNER_DONE', data: {}});
        SessionManager.shared().showToast("Loading Error", err, 'danger');
    })
}


const loadLibraryPublishSummary = (dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation) => {
    const ebaseDs: EbaseDS = SessionManager.shared().getEbaseDS();

    dispatch({type:"LOAD_LIBRARY_PUBLISH_SUMMARY_START"})

    ebaseDs.loadLibrarySummary().then((result: { [key: string]: number; }) => {
        dispatch({type: "LOAD_LIBRARY_PUBLISH_SUMMARY_DONE", data: result})
    }).catch(reason => {
        console.log(reason);
        dispatch({type:"LOAD_LIBRARY_PUBLISH_SUMMARY_DONE", data:null})
    });
};

const viewObjectsWithPublishState = (dispatch: Dispatch<DispatchAction>, publishState: any, ownProps: PropsWithLocation) => {
    dispatch({type:"SEARCH_SET_CRITERIA", data:{term:"", type:"", page:0,max:50, publish:[publishState]}});
    SessionManager.shared().goto("/enviddata");
}

const unmountPublishData = (dispatch: any, ownProps: object) => {
    dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: {}});
}


class PublishViewHome extends React.Component<PublishingHomeProps> {


    constructor(props: PublishingHomeProps & PropsWithLocation)
    {
        super(props);
        this.props.loadAll();
    }

    componentWillUnmount() {
        this.props.unmountData();
    }

    render() {
        return (
            this.props.isLoading ? <Spinner /> : React.cloneElement(<PublishLibraryView/>, this.props)
        );
    }
};

const PublishingHomeContainer = connect(mapStateToProps, mapDispatchToProps)(PublishViewHome)

export default PublishingHomeContainer
