/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateFieldsRequest
 */
export interface UpdateFieldsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFieldsRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFieldsRequest
     */
    field?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateFieldsRequest
     */
    value?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateFieldsRequest
     */
    list?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFieldsRequest
     */
    listOfStrings?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFieldsRequest
     */
    valueAsString?: string;
}

/**
 * Check if a given object implements the UpdateFieldsRequest interface.
 */
export function instanceOfUpdateFieldsRequest(value: object): value is UpdateFieldsRequest {
    return true;
}

export function UpdateFieldsRequestFromJSON(json: any): UpdateFieldsRequest {
    return UpdateFieldsRequestFromJSONTyped(json, false);
}

export function UpdateFieldsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFieldsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['objectId'] == null ? undefined : json['objectId'],
        'field': json['field'] == null ? undefined : json['field'],
        'value': json['value'] == null ? undefined : json['value'],
        'list': json['list'] == null ? undefined : json['list'],
        'listOfStrings': json['listOfStrings'] == null ? undefined : json['listOfStrings'],
        'valueAsString': json['valueAsString'] == null ? undefined : json['valueAsString'],
    };
}

export function UpdateFieldsRequestToJSON(value?: UpdateFieldsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'objectId': value['objectId'],
        'field': value['field'],
        'value': value['value'],
        'list': value['list'],
        'listOfStrings': value['listOfStrings'],
        'valueAsString': value['valueAsString'],
    };
}

