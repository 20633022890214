import {EnvidObjectPublicTypeEnum, EnvidObjectResult, UnformattedText} from "../fetchapi";
import details from "./details";
import * as Diff from 'diff';
import {ArrayChange} from "diff";

export type FieldType = 'text' | 'ftext' | 'text[]'

export interface FieldDefinition {
  field: string;
  name: string;
  fieldType: FieldType;
}

export type EnvidPublishTypes = Extract<EnvidObjectPublicTypeEnum, 'Activity'|'ProjectCategory'|'Aspect'|'Receptor'|'Control'|'TechnicalInput'|'EnvironmentalInput'|'Impact'|'Region'|'Reference'|'Stakeholder'|
  'AspectActivityLink'|'ControlActivityLink'|'ControlReceptorLink'|'ImpactReceptorLink'|'ProjectActivityLink'|'ReceptorRegionLink'|'StakeholderRegionLink'>;

export interface DiffTemplate{
  fields: FieldDefinition[];
}

const DiffViewTemplates: {[key in EnvidPublishTypes]: DiffTemplate} = {

  'Activity': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.shortDesc', name:'Short Description', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'ProjectCategory': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'Aspect': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'Receptor': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.receptorType', name:'Classification', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
      {field: 'details.environmentalInputs', name:'Environmental Inputs', fieldType: 'text[]'},
    ]
  },
  'Control': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.impactName', name: 'Impact', fieldType: 'text'},
      {field: 'details.controlType', name: 'Control Type', fieldType: 'text'},
      {field: 'details.desc', name:'Control Purpose', fieldType: 'ftext'},
      {field: 'details.epo', name:'Environmental Performance Outcome', fieldType: 'text'},
      {field: "details.eps", name:'Environmental Performance Standard', fieldType: "ftext"},
      {field: "details.criteria", name:'Measurement Criteria', fieldType: "ftext"}

    ]
  },
  'TechnicalInput': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'EnvironmentalInput': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'Impact': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.impactName', name:'Impact Name', fieldType: 'text'},
      {field: 'details.aspectName', name:'Aspect', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'Region': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'Reference': {
    fields: [
      {field: 'details.referenceInfo', name:'Name', fieldType: 'text'},
    ]
  },
  'Stakeholder': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.shortDesc', name:'Short Description', fieldType: 'text'},
      {field: 'details.website', name:'Website', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
      {field: 'details.contacts', name:'Contacts', fieldType: 'text[]'},
    ]
  },
  'AspectActivityLink': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.shortDesc', name:'Short Description', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
      {field: 'details.technicalInputs', name:'Technical Inputs', fieldType: 'text[]'},
    ]
  },
  'ControlActivityLink': {
    fields: []
  },
  'ControlReceptorLink': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'ImpactReceptorLink': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'ProjectActivityLink': {
    fields: [
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'ReceptorRegionLink': {
    fields: [
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
  'StakeholderRegionLink': {
    fields: [
      {field: 'details.name', name:'Name', fieldType: 'text'},
      {field: 'details.desc', name:'Description', fieldType: 'ftext'},
    ]
  },
}

/**
 * Retrieves the template associated with a given EnvidObjectResult.
 *
 * @param {EnvidObjectResult} envid - The object containing the environment identifier and its details.
 * @return {DiffTemplate | null} - The corresponding template if found, otherwise null.
 */
export function getTemplateForObject(envid: EnvidObjectResult): DiffTemplate | null {
  const objectType = envid.object?.type as EnvidPublishTypes;
  // console.log("objectType",objectType)
  return DiffViewTemplates[objectType] || null;
}

/**
 * Retrieves the value of a specified field from the details in provided EnvidObjectResult.
 *
 * @param {EnvidObjectResult} envid - The object containing environment details.
 * @param {string} field - The field path to an attribute in the details like 'field.desc'
 * @param {boolean} [usePlain=false] - Flag to determine if plain text details should be used.
 * @return {string} The value of the specified field, or an empty string if not found.
 */
export function getFieldValue(envid: EnvidObjectResult, field: string, usePlain: boolean = false): string {

  // console.log("ENVID", envid);
  if (envid == null || envid.object == null || envid.object.details == null){
    return '';
  }

  if (usePlain){
    if (envid.object.plainTextDetails == null){
      return '';
    }
    return envid.object.plainTextDetails[field]?.text ?? '';
  }
  return details.getValue(envid.object, field);
}


/**
 * Retrieves unformatted text object from the plainTextDetails of a given EnvidObjectResult based on the specified field.
 *
 * @param {EnvidObjectResult} envid - The object containing plain text details.
 * @param {string} field - The field name to retrieve the unformatted text for.
 * @return {UnformattedText | null} The unformatted text associated with the specified field, or null if not available.
 */
export function getUnformattedText(envid: EnvidObjectResult, field: string): UnformattedText | null {
  if (envid == null || envid.object == null || envid.object.plainTextDetails == null){
    return null;
  }
  return envid.object.plainTextDetails[field];
}


// oldObject should never be null, change
export function getDiffedFieldValue(field: string, fieldType: string, oldObject: EnvidObjectResult, newObject: EnvidObjectResult | null): string {

    if (!!oldObject && !!newObject) {
        // const Diff = require('diff');
        let oldValue = getFieldValue(oldObject, field, true);
        let newValue = getFieldValue(newObject, field, true);

        // console.log(oldValue, newValue, splitHtmlString(oldValue), splitHtmlString(newValue));
        // Perform word-based diff
        if (fieldType === "ftext") {
            let oldAttr = oldObject.object?.plainTextDetails ? oldObject.object.plainTextDetails[field]?.attributes : {};
            let newAttr = newObject.object?.plainTextDetails ? newObject.object.plainTextDetails[field]?.attributes : {};
            return highlightDifferencesFormattedString(oldValue, newValue, oldAttr, newAttr);
        }
        if (fieldType === "text") {
            return highlightDifferencesString(oldValue, newValue);
        }
        if (fieldType === "text[]") {
          let oldArr = parseArray(oldValue);
          let newArr = parseArray(newValue);
          return highlightDifferencesStringArr(oldArr, newArr);
        }
        return getFieldValue(newObject, field);
    }
    if (!!oldObject) {
        return getFieldValue(oldObject, field);
    }
    if (!!newObject) {
        return getFieldValue(newObject, field);
    }
    return '';
}

function highlightDifferencesFormattedString(oldString: string, newString: string, oldAttr?: any, newAttr?: any): string {
  oldString = oldString.replace(/\n/g, ' \n ')
  newString = newString.replace(/\n/g, ' \n ')

  let result: string = "";

  let imgRegex = /\$\{(img:.*)\}/;
  let tableRegex = /\$\{(table:.*)\}/;

  let oldArray = oldString.split(' ');
  let newArray = newString.split(' '); //->  /\s+/
  let diff = Diff.diffArrays(oldArray, newArray);

  diff.forEach((part: ArrayChange<string>) => {
    let res: string = "";
    part.value.forEach((value: string) => {
      // let val = "";
      let val = value.replace(/\n/g, "<br>");

      val = val.replace(imgRegex, (match: any, key: any) => {
        // console.log(match, oldAttr[key], newAttr[key]);
        if (!oldAttr[key] && !newAttr[key]) return match;
        if (newAttr[key] === oldAttr[key]) return `<div style="width: fit-content;">${newAttr[key]}</div>`;
        if (!newAttr[key]) return `<div class="border-deleted" style="width: fit-content;">${oldAttr[key]}</div>`;
        if (!oldAttr[key]) return `<div class="border-changed" style="width: fit-content;">${newAttr[key]}</div>`;
        if (oldAttr[key] !== newAttr[key]) return `<div class="border-changed" style="width: fit-content;">${newAttr[key]}</div>`;
        // if (part.added) return `<div style="border: 4px #d4edda solid">${newAttr[key]}</div>`;
        // if (part.removed) return `<div style="border: 4px #f8d7da solid">${oldAttr[key]}</div>`;
      })
      val = val.replace(tableRegex, (match: any, key: any) => {
        // console.log(match, oldAttr[key], newAttr[key]);
        if (!oldAttr[key] && !newAttr[key]) return match;
        if (newAttr[key] === oldAttr[key]) return `<div class="">${newAttr[key].replace(/<table/, "<table class=\" diff-view-table-border \" ")}</div>`;
        if (!newAttr[key]) return `<div class="border-deleted ">${oldAttr[key].replace(/<table/, "<table class=\" diff-view-table-border \" ")}</div>`;
        if (!oldAttr[key]) return `<div class="border-changed ">${newAttr[key].replace(/<table/, "<table class=\" diff-view-table-border \" ")}</div>`;
        if (oldAttr[key] !== newAttr[key]) return `<div class="border-changed ">${newAttr[key].replace(/<table/, "<table class=\" diff-view-table-border \" ")}</div>`;
        // if (part.added) return `<div style="border: 4px #d4edda solid">${newAttr[key]}</div>`;
        // if (part.removed) return `<div style="border: 4px #f8d7da solid">${oldAttr[key]}</div>`;
        return match;
      })

      res += val + " ";
    })

    if (part.added) {
      result += `<span class="highlight-changed">${res}</span>`;
    } else if (part.removed) {
      result += `<span class="highlight-deleted">${res}</span>`;
    } else {
      result += `<span>${res}</span>`;
    }
  });
  return result;
}

function highlightDifferencesString(oldString: string, newString: string) {
  let diff = Diff.diffWords(oldString, newString);
  let result = '';

  diff.forEach((part: any) => {
    if (part.added) {
        result += `<span class="highlight-changed">${part.value}</span>`;
    } else if (part.removed) {
        result += `<span class="highlight-deleted">${part.value}</span>`;
    } else {
        result += `<span>${part.value}</span>`;
    }
  });
  return result;
}

function highlightDifferencesStringArr(oldStringArr: string[], newStringArr: string[]) {
  let diff = Diff.diffArrays(oldStringArr, newStringArr);
  let result = `<ul>`;

  diff.forEach((part: any) => {
    if (part.added) {
      part.value.forEach((val: any) => {
        result += `<li><span class="highlight-changed">${val}</span></li>`;
      });
    } else if (part.removed) {
      part.value.forEach((val: any) => {
        result += `<li><span class="highlight-deleted">${val}</span></li>`;
      });
    } else {
      part.value.forEach((val: any) => {
        result += `<li><span>${val}</span></li>`;
      });
    }
  });
  result += `</ul>`;
  return result;
}

export function parseArray(value: any): string[] {
  if (!value) return [];

  let array = JSON.parse(value);
  if (!array || !Array.isArray(array)) {
    return [];
  }
  return array;
}
