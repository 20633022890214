import React from 'react';

import "froala-editor/js/froala_editor.pkgd.min.js"
import "froala-editor/css/froala_editor.pkgd.min.css"
import 'font-awesome/css/font-awesome.css'
import jq from "../../utils/jq"

import {withTranslation} from "react-i18next";

import CustomFroalaEditor from '../editor/FroalaEditor'
import SessionManager from "../../utils/SessionManager";

class Editor extends React.PureComponent {

    render() {

        //setting the same toolbar for all sizes at the moment but if we want different buttons for
        //different sizes we can.
        //inlineClasses (ebase-editor-highlight) defined in index.html
// console.log("Editor:: model ",this.props.model);
        //ImkkxiA6mg1B-8H1sf==

        const overlayDblClick = () => {
            SessionManager.shared().showToast("Editing Disabled","Checkout object to begin editing", 'success');
        }

        const {t} = this.props;

        let toolbarButtons = this.props.toolbarButtons ?? ['bold', 'italic', 'underline','align','formatUL','formatOL','indent','outdent',
            'subscript','superscript', '|','undo','redo','|','insertImage','insertTable','insertLink','inlineClass',
            'clearFormatting'
        ];

        let blankCellStyle = this.props.modal ? 'fr-selected-cell' : '';

        let config = {

            placeholderText: '',
            charCounterCount: false,
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            imageUploadMethod: "POST",
            toolbarButtons:toolbarButtons,
            toolbarButtonsMD:toolbarButtons,
            toolbarButtonsSM:toolbarButtons,
            toolbarButtonsXS:toolbarButtons,
            toolbarSticky: false,
            iFrame: false,
            height:this.props.height,
            imageEditButtons: ['imageReplace', 'imageAlign',
                'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit',
                'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt',
                'imageSize'],
            inlineClasses:{
                'ebase-editor-yellow-highlight': t('Yellow Highlight'),
                'ebase-editor-pink-highlight': t('Pink Highlight'),
                'ebase-editor-blue-highlight': t('Blue Highlight'),
                'ebase-editor-green-highlight': t('Green Highlight'),
                'ebase-table-caption': t('Table Caption'),
                'ebase-image-caption': t('Image Caption'),
                'ebase-heading-4': t('Heading 4'),
                'ebase-heading-5': t('Heading 5')
            },
            tableCellMultipleStyles: false,
            tableCellStyles: {
                [blankCellStyle] : 'None',
                'ebase-editor-yellow-highlight' : t('Yellow Highlight'),
                'ebase-editor-pink-highlight': t('Pink Highlight'),
            },
            tableMultipleStyles: false,
            tableStyles:{
                '': 'None',
                'fr-alternate-rows' : t('Alternate Rows'),
                'ebase-table-custom-1': t('Custom Table 1'),
                'ebase-table-custom-2': t('Custom Table 2'),
            },
            zIndex: this.props.zIndex,
            heightMin:this.props.maxHeight ? this.props.maxHeight : 80,
            heightMax:this.props.maxHeight,
            theme:"ebase_editor",
            spellcheck:true,
            inlineStyles:{'Highlight':'color: blue; background-color:yellow;'},
            key:"yE6C4B4E4jC10D7A5A5B2A3F3E2C2B4B-16gaevcD3kfgC6mx==",
            imageUploadURL: "/ebase/envid-admin/write-enviddata-image",
            imageUploadParams:{object_id:this.props.currentObject},
            events:{'froalaEditor.image.beforeUpload': function (e, editor, images) {
                                                            console.log("-- froalaEditor.image.beforeUpload --");
                                                            console.log("e: "+e);
                                                            console.log("editor: "+editor);
                                                            console.log("Images: "+JSON.stringify(images));
                                                            return true;
                                                        },
                    'froalaEditor.image.uploaded': function (e, editor, response) {
                        console.log("-- froalaEditor.image.uploaded --")
                        console.log("e: "+e);
                        console.log("editor: "+editor);
                        console.log("response: "+JSON.stringify(response));
                        return true;
                    }
            }
        };


        if (this.props.onBlur !== undefined)
        {
            config.events["froalaEditor.blur"] = function(e, editor) {this.props.onBlur();}.bind(this);
        }

        return (
            <div className=" w-100 h-100 position-relative">
                <CustomFroalaEditor
                    tag="div"
                    model={this.props.model}
                    onModelChange={this.props.handleModelChange}
                    config={config}
                    disabled={this.props.disabled}
                />
                {this.props.disabled && this.props.toolbarButtons == null ? <div onDoubleClick={overlayDblClick} style={{zIndex: 10}} className="w-100 h-100 position-absolute top-0 start-0 bg-secondary-subtle opacity-25"></div> : <></>}
            </div>
        );
    }
}

jq.find("textarea").froalaEditor().on().on('froalaEditor.image.uploaded', function (e, editor, response) {
    console.log("-- froalaEditor.image.uploaded --")
    console.log("e: "+e);
    console.log("editor: "+editor);
    console.log("response: "+JSON.stringify(response));
    return true;
});

export default withTranslation()(Editor);
