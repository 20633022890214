/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidObjectSmallResult } from './EnvidObjectSmallResult';
import {
    EnvidObjectSmallResultFromJSON,
    EnvidObjectSmallResultFromJSONTyped,
    EnvidObjectSmallResultToJSON,
} from './EnvidObjectSmallResult';

/**
 * 
 * @export
 * @interface ProjectSmallResult
 */
export interface ProjectSmallResult {
    /**
     * 
     * @type {string}
     * @memberof ProjectSmallResult
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSmallResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSmallResult
     */
    desc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSmallResult
     */
    archived?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProjectSmallResult
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {EnvidObjectSmallResult}
     * @memberof ProjectSmallResult
     */
    region?: EnvidObjectSmallResult;
    /**
     * 
     * @type {Array<EnvidObjectSmallResult>}
     * @memberof ProjectSmallResult
     */
    projectCategories?: Array<EnvidObjectSmallResult>;
}

/**
 * Check if a given object implements the ProjectSmallResult interface.
 */
export function instanceOfProjectSmallResult(value: object): value is ProjectSmallResult {
    return true;
}

export function ProjectSmallResultFromJSON(json: any): ProjectSmallResult {
    return ProjectSmallResultFromJSONTyped(json, false);
}

export function ProjectSmallResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSmallResult {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['object_id'] == null ? undefined : json['object_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'desc': json['desc'] == null ? undefined : json['desc'],
        'archived': json['archived'] == null ? undefined : json['archived'],
        'details': json['details'] == null ? undefined : json['details'],
        'region': json['region'] == null ? undefined : EnvidObjectSmallResultFromJSON(json['region']),
        'projectCategories': json['project_categories'] == null ? undefined : ((json['project_categories'] as Array<any>).map(EnvidObjectSmallResultFromJSON)),
    };
}

export function ProjectSmallResultToJSON(value?: ProjectSmallResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_id': value['objectId'],
        'name': value['name'],
        'desc': value['desc'],
        'archived': value['archived'],
        'details': value['details'],
        'region': EnvidObjectSmallResultToJSON(value['region']),
        'project_categories': value['projectCategories'] == null ? undefined : ((value['projectCategories'] as Array<any>).map(EnvidObjectSmallResultToJSON)),
    };
}

