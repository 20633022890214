import {EnvidObjectResult} from "../../fetchapi";
import React, {ReactElement} from "react";
import {DiffTemplate, FieldDefinition, getDiffedFieldValue, getFieldValue, parseArray} from "../../utils/diffDetails";
import {getTemplateForObject} from "../../utils/diffDetails";
import EnvidObjectIcon from "../common/EnvidObjectIcon";
import Editor from "../common/Editor";
import envid from "../../utils/envid";

export interface DiffContentProps {
  oldObject: EnvidObjectResult | undefined;
  newObject: EnvidObjectResult;
  showDifference: boolean;
  customName?: string;
}

export default function DiffContent(props: DiffContentProps): ReactElement {
    const { oldObject, newObject, showDifference } = props;
    const template: DiffTemplate | null = getTemplateForObject(newObject);

    let content: ReactElement[] = []
    let contentKey = 0;
    content.push(<HeaderRow key={"content_" + ++contentKey} oldObject={oldObject} newObject={newObject} customName={props.customName}/>)
    content.push(<SubHeaderRow key={"content_" + ++contentKey} oldObject={oldObject} newObject={newObject}/>)
    if (!!template) {
        template.fields.map((f, index) => {
            if (showDifference) {
                content.push(<ContentRow key={"content_" + ++contentKey} index={index} field={f} oldObject={oldObject} newObject={newObject}/>);
            } else {
                content.push(<RenderedContentRow key={"content_" + ++contentKey} index={index} field={f} oldObject={oldObject} newObject={newObject}/>);
            }
        });
    }
    content.push(<FooterRow key={"content_" + ++contentKey} />)

    return (
        <div className="d-flex flex-column">
            {content}
        </div>
    )
}


interface HeaderRowProps {
    oldObject: EnvidObjectResult | undefined;
    newObject: EnvidObjectResult;
    customName?: string;
}

function HeaderRow(props: HeaderRowProps): ReactElement {
    const {oldObject, newObject} = props;

    let oldHeader = oldObject ? (oldObject.object?.versionDetails?.state ?? "") + " Version " + (oldObject.object?.versionDetails?.version ? " (" + oldObject.object?.versionDetails?.version + ")" : "") : "";
    let newHeader = "Latest Version " + (newObject.object?.versionDetails?.state ? " (" + newObject.object?.versionDetails?.state + ")" : "");

    let oldName = oldObject?.object?.details?.displayName ?? "";
    let newName = newObject.object?.details?.displayName ?? "";

    let oldStatus = oldObject?.object?.publishingInfo?.state ?? "";
    let newStatus = newObject.object?.publishingInfo?.state ?? "";

    let oldType = oldObject?.object?.type ?? "";
    let newType = newObject.object?.type ?? "";

    return (
        <div key={"Header"} className="d-flex justify-content-between">
            <div style={{marginRight: '12px'}} className="w-50 px-3 pt-3 pb-0 bg-white rounded-top-2">
                {oldObject ?
                <div className="d-flex flex-column align-items-center">
                    <h1>{oldHeader}</h1>
                    <div className="d-flex flex-wrap justify-content-center gap-4">
                        <h4>{"Object: " + (props.customName ?? oldName)}</h4>
                        <h4>{"Status: "}
                            <span style={{...envid.getPublishTextStyle(oldStatus), width: 'fit-content', fontSize: "12px"}} className="label">{envid.getPublishStateName(oldStatus)}</span>
                        </h4>
                        <h4><EnvidObjectIcon type={oldType} showName={true}/></h4>
                    </div>
                </div>
                    : <div className="w-100 ">
                        <div className="d-flex flex-column align-items-center">
                            <h1>{"No Older Published Versions"}</h1>
                        </div>
                    </div>}
            </div>

            <div style={{marginLeft: '12px'}} className="w-50 px-3 pt-3 pb-0 bg-white rounded-top-2">
                <div className="d-flex flex-column align-items-center">
                    <h1>{newHeader}</h1>
                    <div className="d-flex flex-wrap justify-content-center gap-4">
                        <h4>{"Object: " + (props.customName ?? newName)}</h4>
                        <h4>{"Status: "}
                            <span style={{...envid.getPublishTextStyle(newStatus), width: 'fit-content', fontSize: "12px"}} className="label">{envid.getPublishStateName(newStatus)}</span>
                        </h4>
                        <h4><EnvidObjectIcon type={newType} showName={true}/></h4>
                    </div>
                </div>
            </div>
        </div>
    )
}


interface SubHeaderRowProps {
    oldObject: EnvidObjectResult | undefined;
    newObject: EnvidObjectResult;
}

function SubHeaderRow(props: SubHeaderRowProps): ReactElement {
    const {oldObject, newObject} = props;

    return (
        <div key={"SubHeader"} className="d-flex justify-content-between">
            <div style={{marginRight: '12px'}} className="w-50 px-3 py-0 bg-white">
                {oldObject ?
                <hr className="py-0"/>
                : <></>}
            </div>

            <div style={{marginLeft: '12px'}} className="w-50 px-3 py-0 bg-white">
                <hr className="py-0"/>
            </div>
        </div>
    )
}

interface ContentRowProps {
    field: FieldDefinition;
    oldObject: EnvidObjectResult | undefined;
    newObject: EnvidObjectResult;
    index: number;
}

function ContentRow(props: ContentRowProps): ReactElement {
    const {oldObject, newObject, field} = props;

    // const oldValue = getFieldValue(oldObject, field.field)
    const oldValue = oldObject ? getDiffedFieldValue(field.field, field.fieldType, oldObject, oldObject) : "";
    const newValue = oldObject ? getDiffedFieldValue(field.field, field.fieldType, oldObject, newObject) :
        getDiffedFieldValue(field.field, field.fieldType, newObject, newObject);

    return (
        <div className="d-flex justify-content-between">
            <div style={{marginRight: '12px'}} className={"w-50 p-3 bg-white d-flex flex-column overflow-auto"}>
                {oldObject ?
                <div className="w-100 font-bold">{field.name}</div>
                    : <></>}
                <div className="w-100" dangerouslySetInnerHTML={{__html: oldValue}}></div>
            </div>

            <div style={{marginLeft: '12px'}} className={"w-50 p-3 bg-white d-flex flex-column overflow-auto"}>
                <div className="w-100 font-bold">{field.name}</div>
                <div className="w-100 " dangerouslySetInnerHTML={{__html: newValue}}></div>
            </div>
        </div>
  )
}

function RenderedContentRow(props: ContentRowProps): ReactElement {
    const {oldObject, newObject, field} = props;


    let oldValue, newValue = '';
    let oldHtml, newHtml;
    if (oldObject) {
        if (field.fieldType === 'ftext') {
            oldValue = getFieldValue(oldObject, field.field, false);
            newValue = getFieldValue(newObject, field.field, false);
            oldHtml = <Editor
                model={oldValue}
                disabled={true}
                modal={false}
                toolbarButtons={[]}/>;
            newHtml = <Editor
                model={newValue}
                disabled={true}
                modal={false}
                toolbarButtons={[]}/>;
        } else if (field.fieldType === 'text[]') {
            oldValue = getFieldValue(oldObject, field.field, true);
            newValue = getFieldValue(newObject, field.field, true);
            oldHtml = <ul>{parseArray(oldValue).map((item: any) => {
                return <li>{item}</li>;
            })}</ul>;
            newHtml = <ul>{parseArray(newValue).map((item: any) => {
                return <li>{item}</li>;
            })}</ul>;
        } else {
            oldValue = getFieldValue(oldObject, field.field, true);
            newValue = getFieldValue(newObject, field.field, true);
            oldHtml = oldValue;
            newHtml = newValue;
        }
    }

    return (
        <div className="d-flex justify-content-between">
            <div style={{marginRight: '12px'}} className={"w-50 p-3 bg-white d-flex flex-column overflow-auto"}>
                {oldObject ?
                    <div className="w-100 font-bold">{field.name}</div>
                    : <></>}
                <div className="w-100">
                    {oldHtml}
                </div>
            </div>

            <div style={{marginLeft: '12px'}} className={"w-50 p-3 bg-white d-flex flex-column overflow-auto"}>
                <div className="w-100 font-bold">{field.name}</div>
                <div className="w-100 ">
                    {newHtml}
                </div>
            </div>
        </div>
    )
}

interface FooterRowProps {}

function FooterRow(props: FooterRowProps): ReactElement {
    return (
        <div className="d-flex justify-content-between">
            <div style={{marginRight: '12px'}} className="w-50 p-2 bg-white rounded-bottom-2"></div>
            <div style={{marginLeft: '12px'}} className="w-50 p-2 bg-white rounded-bottom-2"></div>
        </div>
    )
}
